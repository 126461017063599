import { z } from "zod";
import { ISiteMapEntry, ISiteMapNode } from "~/interfaces/sitemap-entry";
import { SiteMapItemSchema, SiteMapSchema, SiteMapContentItemSchema } from "~/schemas/sitemap";

export class SiteMap {
    private  PROGRAMME_TYPE_ACADEMIC_PROGRAMMES = 'Academic Qualifications';
    private  PROGRAMME_TYPE_FORUMS = 'Forums';
    private  PROGRAMME_TYPE_MASTERCLASSES = 'Masterclasses';
    private  PROGRAMME_TYPE_EVENTS = 'Events';
    private  PROGRAMME_TYPE_EXECUTIVE_PROGRAMMES ='Executive Programmes';
    private  PROGRAMME_TYPE_COACHING_COURSES = 'Coaching Courses';
    private  PROGRAMME_TYPE_CONFERENCE =  'Conference'
    private  PROGRAMME_TYPE_COURSES = 'Courses';

    private entries: Array<ISiteMapEntry> = [];
    private level1Entries:  Array<ISiteMapEntry> = [];
    private level2Entries: Array<ISiteMapEntry> = [];
    private level3Entries: Array<ISiteMapEntry> = [];
    
    constructor(sitemapJson: z.infer<typeof  SiteMapSchema>) {

        if (typeof(sitemapJson) !== 'undefined' && sitemapJson != null) {
            let level1Entry: ISiteMapEntry;
            // Home entry
            let homeEntry: ISiteMapEntry =  {
                "Name": sitemapJson.Name,
                "Level": 0,
                "URL": sitemapJson.URL,
                "Category": sitemapJson.Name,
                "PagePath": "/",
                "Pages": []
              };
              this.entries.push(homeEntry);

            //Hardcoded Level 1 SiteMapEntries
            //404
            level1Entry = {
                "Name": '404',
                "Level": 1,
                "URL": '/404',
                "Category": '404',
                "PagePath": `${homeEntry.URL}|/404`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);

            // Search
            level1Entry = {
                "Name": 'Search',
                "Level": 1,
                "URL": '/search',
                "Category": 'Search',
                "PagePath": `${homeEntry.URL}|/search`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);

            //Account
            level1Entry = {
                "Name": 'Account',
                "Level": 1,
                "URL": '/account',
                "Category": 'Account',
                "PagePath": `${homeEntry.URL}|/account`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);

            let level2Entry: ISiteMapEntry= {
                "Name": 'Sign up',
                "Level": 2,
                "URL": '/account/sign-up',
                "Category": 'Sign up',
                "PagePath": `${homeEntry.URL}|${level1Entry.URL}|/account/sign-up`,
                "Pages": [homeEntry, level1Entry]
            }
            this.level2Entries.push(level2Entry);
            level2Entry = {
                "Name": 'Login',
                "Level": 2,
                "URL": '/account/login',
                "Category": 'Login',
                "PagePath": `${homeEntry.URL}|${level1Entry.URL}|/account/login`,
                "Pages": [homeEntry, level1Entry]
            }
            this.level2Entries.push(level2Entry);

            
            //Profile
            level1Entry = {
                "Name": 'Profile',
                "Level": 1,
                "URL": '/profile',
                "Category": 'Profile',
                "PagePath": `${homeEntry.URL}|/profile`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);
            level2Entry = {
                "Name": 'My learning journey',
                "Level": 2,
                "URL": '/profile/my-learning-journey',
                "Category": 'My learning journey',
                "PagePath": `${homeEntry.URL}|${level1Entry.URL}|/profile/my-learning-journey`,
                "Pages": [homeEntry, level1Entry]
            }
            this.level2Entries.push(level2Entry);

            level2Entry = {
                "Name": 'Personal details',
                "Level": 2,
                "URL": '/profile/personal-details',
                "Category": 'Personal details',
                "PagePath": `${homeEntry.URL}|${level1Entry.URL}|/profile/personal-details`,
                "Pages": [homeEntry, level1Entry]
            }
            this.level2Entries.push(level2Entry);

            level2Entry = {
                "Name": 'Change my password',
                "Level": 2,
                "URL": '/profile/change-my-password',
                "Category": 'Change my password',
                "PagePath": `${homeEntry.URL}|${level1Entry.URL}|/profile/change-my-password`,
                "Pages": [homeEntry, level1Entry]
            }
            this.level2Entries.push(level2Entry);

             //Course Finder - level 1 and Level 2 
             level1Entry = {
                "Name": 'Course finder',
                "Level": 1,
                "URL": '/course-finder',
                "Category": 'Course finder',
                "PagePath": `${homeEntry.URL}|/course-finder`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);

            level2Entry = {
                "Name": 'Results',
                "Level": 2,
                "URL": '/course-finder/results',
                "Category": 'Results',
                "PagePath": `${homeEntry.URL}|${level1Entry.URL}|/course-finder/results` ,
                "Pages": [homeEntry, level1Entry]
            }
            this.level2Entries.push(level2Entry);
            
            
            //Contact
            level1Entry = {
                "Name": 'Contact us',
                "Level": 1,
                "URL": '/contact-us',
                "Category": 'Course finder',
                "PagePath": `${homeEntry.URL}|/contact-us`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);
            
            //Organisastion
            level1Entry = {
                "Name": 'For organisation',
                "Level": 1,
                "URL": '/pages/for-organisations',
                "Category": 'For organisation',
                "PagePath": `${homeEntry.URL}|/pages/for-organisations`,
                "Pages": [homeEntry]
            }

            level2Entry = {
                "Name": 'A solution for your organisation',
                "Level": 2,
                "URL": '/a-solution-for-your-organisation',
                "Category": 'A solution for your organisation',
                "PagePath": `${homeEntry.URL}|${level1Entry.URL}|/a-solution-for-your-organisation` ,
                "Pages": [homeEntry, level1Entry]
            }
    
              //Course-listing pages - Level 1 
              sitemapJson.Pages.courselist.forEach((courseListing: z.infer<typeof SiteMapItemSchema>) => {
    
                // Do not add events, conferences or forums. will be under a combined node
                if (courseListing.Name !== this.PROGRAMME_TYPE_EVENTS
                    && courseListing.Name !== 'Conferences'
                    && courseListing.Name !==  this.PROGRAMME_TYPE_FORUMS) {
                        let level1Entry: ISiteMapEntry = {
                            "Name": courseListing.Name,
                            "Level": 1,
                            "URL": courseListing.URL,
                            "Category": courseListing.Name,
                            "PagePath": `${homeEntry.URL}|${courseListing.URL}`,
                            "Pages": [homeEntry]
                        }
                        this.level1Entries.push(level1Entry);
                }
               
            })  
    
            // Programmes - Level1, Level2
            sitemapJson.Pages.Programmes.forEach((programme: z.infer<typeof ProgrammeSchema>) => {
                //Get Level 1
                let level1Entry = this.getEntryByCourseClassificationOfLevel1(programme.CourseClassification);
    
                if (level1Entry != null) {
                   
                   // Override the Level1 if its one of these
                    if (level1Entry.Category == this.PROGRAMME_TYPE_EVENTS 
                            || level1Entry.Category == this.PROGRAMME_TYPE_FORUMS
                            || level1Entry.Category == this.PROGRAMME_TYPE_CONFERENCE) {
                        
                        const categoryDescription = 'Conferences, Forums & Events';
                        const urlPath = '/course-listing/conferences-events-forums';
    
                        level1Entry.Name = categoryDescription;
                        level1Entry.URL = urlPath;
                        level1Entry.Category = categoryDescription;
                        level1Entry.PagePath = `${homeEntry.URL}|${urlPath}`;
                    }
                    
                     // Create Level2 SIteMapEntry
                    let level2Entry: ISiteMapEntry= {
                        "Name": programme.Name,
                        "Level": 2,
                        "URL": programme.URL,
                        "Category": programme.Name,
                        "PagePath": `${homeEntry.URL}|${level1Entry.URL}|${programme.URL}`,
                        "Pages": [homeEntry, level1Entry]
                    
                    }
                    this.level2Entries.push(level2Entry);
               
                    //Create Level3 Application
                    programme.Applications.forEach((application: z.infer<typeof ApplicationSchema>) => {
                        // Create Level3 SIteMapEntry
                        let level3Entry: ISiteMapEntry = {
                            "Name": application.Name,
                            "Level": 3,
                            "URL": application.URL,
                            "Category": application.Name,
                            "PagePath": `${homeEntry.URL}|${level1Entry.URL}|${level2Entry.URL}|${application.URL}`,
                            "Pages": [homeEntry, level1Entry, level2Entry]
                        }
                        this.level3Entries.push(level3Entry);
                    });
                }
    
               
            });
    
            // News - Level1 & Level 2
            level1Entry = {
                "Name": 'News',
                "Level": 1,
                "URL": '/news',
                "Category": 'News',
                "PagePath": `${homeEntry.URL}|/news`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);
    
            sitemapJson.Pages.news.forEach((news: z.infer<typeof SiteMapItemSchema>) => {
                  // Create Level2 SIteMapEntry
                  let level2Entry: ISiteMapEntry= {
                    "Name": news.Name,
                    "Level": 2,
                    "URL": news.URL,
                    "Category": news.Name,
                    "PagePath": `${homeEntry.URL}|${level1Entry.URL}|${news.URL}`,
                    "Pages": [homeEntry, level1Entry]
                }
                this.level2Entries.push(level2Entry);
            });
              
             // Faculty - Level1 & Level 2
            level1Entry= {
                "Name": 'Faculty',
                "Level": 1,
                "URL": '/faculty',
                "Category": 'Faculty',
                "PagePath": `${homeEntry.URL}|/faculty`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);
    
            sitemapJson.Pages.faculty.forEach((faculty: z.infer<typeof SiteMapItemSchema>) => {
                // Create Level2 SIteMapEntry
                let level2Entry: ISiteMapEntry= {
                    "Name": faculty.Name,
                    "Level": 2,
                    "URL": faculty.URL,
                    "Category": faculty.Name,
                    "PagePath": `${homeEntry.URL}|${level1Entry.URL}|${faculty.URL}`,
                    "Pages": [homeEntry, level1Entry]
                }
                this.level2Entries.push(level2Entry);
            });
    
            // Content - Level1 & Level 2
            level1Entry= {
                "Name": 'About us',
                "Level": 1,
                "URL": '/pages/landing',
                "Category": 'Landing',
                "PagePath": `${homeEntry.URL}|/pages/landing`,
                "Pages": [homeEntry]
            }
            this.level1Entries.push(level1Entry);

            sitemapJson.Pages.content.forEach((content: z.infer<typeof SiteMapContentItemSchema>) => {
                // Create Level2 SIteMapEntry
                if (content.URL !== '/pages/landing'){
                    let level2Entry: ISiteMapEntry= {
                        "Name": content.Name,
                        "Level": 2,
                        "URL": content.URL.replace('.json', ''),
                        "Category": content.Name,
                        "PagePath": `${homeEntry.URL}|${level1Entry.URL}|${content.URL}`,
                        "Pages": [homeEntry, level1Entry]
                    }
                    this.level2Entries.push(level2Entry);

                    content.ContentPages.forEach((childContentPage: z.infer<typeof SiteMapContentItemSchema>) => {
                        let level3Entry: ISiteMapEntry = {
                            "Name": childContentPage.Name,
                            "Level": 3,
                            "URL": childContentPage.URL.replace('.json', ''),
                            "Category": childContentPage.Name,
                            "PagePath": `${homeEntry.URL}|${level1Entry.URL}|${content.URL}|${childContentPage.URL}`,
                            "Pages": [homeEntry, level1Entry, level2Entry]
                        }
                        this.level3Entries.push(level3Entry);
                    });
                }
            });
    
            //Add all level 1 up to 4
            this.entries = this.entries.concat(this.level1Entries, this.level2Entries,  this.level3Entries);
        }
       
    }

    getEntryByUrl(url: String) {
        let output = null;
      
        if(typeof(url) !=='undefined' && url != null) {
            let lookupUrl: String = '';
            //These are the hybrid pages in nuxt, mapped to umbraco page in the Sitemap classes entries ISiteMapEntry url prop.
            switch(url){
                case '/a-solution-for-your-organisation':
                case '/coaching-listing':
                case '/info-central':
                    lookupUrl = `/pages${url}`;
                break;
                default:
                    lookupUrl = url;
                break;
            }

            let existingEntry = this.entries.find((entry) => {
                return entry.URL === lookupUrl.toLowerCase();
            });
    
            if (typeof(existingEntry) !== 'undefined') {
                output = existingEntry;
            }
        }
       
        return output;
    }

    getEntryByCourseClassificationOfLevel1(courseClassification: String) {
        let output = null;

        if(courseClassification === 'Conference' 
            || courseClassification === this.PROGRAMME_TYPE_EVENTS
            || courseClassification === this.PROGRAMME_TYPE_FORUMS){
            let groupedEntry = this.level1Entries.find((entry) => {
                return entry.URL === '/course-listing/conferences-events-forums';
            });

            if(typeof(groupedEntry) !== 'undefined'){
                output = groupedEntry;
            }
            
        }
        else if (courseClassification === this.PROGRAMME_TYPE_COACHING_COURSES) {
            let coachingEntry = this.level1Entries.find((entry) => {
                return entry.URL === '/course-listing/coaching';
            });

            if(typeof(coachingEntry) !== 'undefined'){
                output = coachingEntry;
            }
        }
        else {
            let existingEntry = this.level1Entries.find((entry) => {
                return entry.Name === courseClassification;
            });
            if(typeof(existingEntry) !== 'undefined'){
                output = existingEntry;
            }
        }

        return output;
    }

    createTree() {
        const root = {};
        this.entries.forEach((entry: ISiteMapEntry) => {

            if (entry.Name !='404') {

                let pages = entry.Pages;
                let existingRoot:ISiteMapNode | undefined =  undefined;
                let existingLevel1:ISiteMapNode | undefined =  undefined;
                let existingLevel2:ISiteMapNode | undefined =  undefined;
                let existingLevel3:ISiteMapNode | undefined =  undefined;
                let existingLevel4:ISiteMapNode | undefined=  undefined;

                pages.forEach((page: ISiteMapEntry) => {

                    //Create a node on the root intially
                    if(page.Level == 0) {
                        existingRoot = root[page.URL] ; 
                        if (typeof(existingRoot) === 'undefined') {

                            let node: ISiteMapNode = {
                                URL: page.URL,
                                Page: page,
                                List: []
                            };

                            root[page.URL] = node;
                            existingRoot = node;
                        }
                    }
                    else if (page.Level ==1) {
                        let node: ISiteMapNode;

                        existingLevel1 = existingRoot.List.find((listItem: ISiteMapNode) => {
                            return listItem.URL == page.URL;
                        });
                        if (typeof(existingLevel1) === 'undefined') {
                            node = {
                                URL: page.URL,
                                Page: page,
                                List: []
                            };

                            // Add the Node to the parent Node
                            existingRoot.List.push(node);
                            existingLevel1 = node;
                        }
                        
                    }
                    else if (page.Level ==2){
                        existingLevel2 = existingLevel1.List.find((listItem: ISiteMapNode) => {
                            return listItem.URL == page.URL;
                        });
                        if (typeof(existingLevel2) === 'undefined') {

                            let node: ISiteMapNode = {
                                URL: page.URL,
                                Page: page,
                                List: []
                            };
                            // Add the Node to the parent Node
                            existingLevel1.List.push(node);
                            existingLevel2 = node;
                        }
                    }
                    // Note: This if we need to have more levels
                    else if (page.Level ==3){
                        existingLevel3 = existingLevel2.List.find((listItem: ISiteMapNode) => {
                            return listItem.URL == page.URL;
                        });
                        if (typeof(existingLevel3) === 'undefined') {

                            let node: ISiteMapNode = {
                                URL: page.URL,
                                Page: page,
                                List: []
                            };
                            // Add the Node to the parent Node
                            existingLevel2.List.push(node);
                            existingLevel3 = node;
                        }
                    }
                    // TODO: if we need more levels add an else part. But more than 4levels will defeat the point of a breadcrumbs
                    // else if (page.Level ==4){
                    //     existingLevel4 = existingLevel3.List.find((listItem: ISiteMapNode) => {
                    //         return listItem.URL == page.URL;
                    //     });
                    //     if (typeof(existingLevel4) === 'undefined') {

                    //         let node: ISiteMapNode = {
                    //             URL: page.URL,
                    //             Page: page,
                    //             List: []
                    //         };
                    //         // Add the Node to the parent Node
                    //         existingLevel3.List.push(node);
                    //         existingLevel4 = node;
                    //     }
                    // }

                });

                //Add the Entry to the tree 
                if(entry.Level == 1){
            
                    let node: ISiteMapNode;
                    if (typeof(existingRoot)  !== 'undefined') {
                        node= {
                            URL: entry.URL,
                            Page: entry,
                            List: []
                        };
                        existingRoot.List.push(node);
                    }
                }
                else if(entry.Level == 2){
                    let node: ISiteMapNode;
                    if (typeof(existingLevel1)  !== 'undefined') {
                        node= {
                            URL: entry.URL,
                            Page: entry,
                            List: []
                        };
                        existingLevel1.List.push(node);
                    }
                }
                else if(entry.Level == 3){
                    let node: ISiteMapNode;
                    if (typeof(existingLevel2)  !== 'undefined') {
                        node= {
                            URL: entry.URL,
                            Page: entry,
                            List: []
                        };
                        existingLevel2.List.push(node);
                    }
                }
            }
        });
        return root;
    }

    getUrlBreadcrumb(url: string) {
        let level1EntryName: String = '';
        let level1Entry: ISiteMapEntry | undefined;
        const foundEntry = this.entries.find((entry) => {
            return entry.PagePath.endsWith(url);
        });

        if(typeof(foundEntry) !== 'undefined') {
            level1Entry = foundEntry.Pages.find((pageEntry) => {
                return pageEntry.Level == 1;
            });

            if(typeof(level1Entry) !== 'undefined'){
                level1EntryName = level1Entry.Name;
            }
        }
        return level1EntryName;
    }
}
